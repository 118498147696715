import { Button, ButtonGroup } from '@material-ui/core';
import useChangePreferredLocale from '@sprinx/react-globalize/useChangePreferredLocale';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { localeState, supportedLocalesState } from '../../../api/appState';

export type MainNavigationContentItemProfileProps = StandardDivProps<MainNavigationContentItemProfileClassKey> & {
  classes?: Partial<Record<MainNavigationContentItemProfileClassKey, string>>;
  className?: string;
};

export type MainNavigationContentItemProfileClassKey = 'root';

const themeSettings = { name: 'MainNavigationContentItemProfile' };

const AppBarActionLocale: React.FC<MainNavigationContentItemProfileProps> = () => {
  const locale = useRecoilValue(localeState);
  const supportedLocales = useRecoilValue(supportedLocalesState);
  const changePreferredLocale = useChangePreferredLocale();

  const handleSetLocale = useCallback(
    (nextLocale: string) => () => {
      if (changePreferredLocale) {
        changePreferredLocale(nextLocale);
      }
    },
    [changePreferredLocale],
  );
  return (
    <>
      <ButtonGroup variant='outlined' aria-label='outlined button group'>
        {supportedLocales.map((value) =>
          value.locale === locale ? (
            <Button key={value.locale} onClick={handleSetLocale(value.locale)} variant='text' disabled>
              {value.label}
            </Button>
          ) : (
            <Button key={value.locale} onClick={handleSetLocale(value.locale)} variant='text' color='primary'>
              {value.label}
            </Button>
          ),
        )}
      </ButtonGroup>
    </>
  );
};

AppBarActionLocale.displayName = 'AppBarActionLocale';

export default withThemeProps(themeSettings)(AppBarActionLocale);
