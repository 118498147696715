import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import TextField from '@sprinx/react-mui-fields/TextField';
import qs from 'qs';
import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AfterPageProps } from '../../@sprinx/react-after-razzle';
import { localeState, useApiClient } from '../../api/appState';
import useLoginHandler from '../../api/auth/useLoginHandler';
import { customerNotificationOpenState } from '../../api/customerNotification';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import LoginPageActionLocale from '../../layouts/MainLayout/components/LoginPageActionLocale';
import LoginPageLogo from './LoginPageLogo';

export type LoginPageProps = AfterPageProps;
export type LoginPageClassNameKey =
  | 'root'
  | 'content'
  | 'headline'
  | 'logo'
  | 'fields'
  | 'actions'
  | 'forgotFieldsContainer'
  | 'selectLocale';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<LoginPageClassNameKey, {}>({
    root: {
      paddingTop: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
    },
    content: {
      boxShadow: theme.shadows[4],
    },
    headline: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2.5, 1.5),
      display: 'flex',
      justifyContent: 'center',
    },
    selectLocale: {
      // padding: theme.spacing(2.5, 1.5),
      display: 'flex',
      justifyContent: 'center',
    },
    logo: {
      width: theme.spacing(19),
    },
    fields: {
      padding: theme.spacing(2, 3),
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      padding: theme.spacing(2, 3),
      '& > a': {
        cursor: 'pointer',
        marginRight: 'auto',
      },
    },
    forgotFieldsContainer: {
      padding: theme.spacing(0, 3, 3, 3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  }),
);

const initialValues = {
  username: '',
  password: '',
  forgotUser: '',
};

interface Values {
  forgotUser: string;
  password: string;
  username: string;
}

const LoginPage: React.FC<LoginPageProps> = ({ location, history }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<Values>(initialValues);
  const [error, setError] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const apiClient = useApiClient();
  const setNotificationOpen = useSetRecoilState(customerNotificationOpenState);
  const locale = useRecoilValue(localeState);

  const handleChange = React.useCallback(
    (fieldName: keyof Values) => (value: string | undefined) => {
      setValues((ps) => ({ ...ps, [fieldName]: value }));
    },
    [],
  );

  const handlePasswordReset = React.useCallback(() => {
    if (values.forgotUser) {
      apiClient
        .post<{ email: string }, { locale: string; username: string }>('/v1/users/renew-password', {
          username: values.forgotUser,
          locale: locale,
        })
        .then(
          ({ email }) => {
            showMessage({ message: t('homePage/passwordReset/passwordSent') + `${email}`, variant: 'success' });
            setValues(initialValues);
            setOpen(false);
          },
          () => {
            showMessage({ message: t('homePage/passwordReset/error'), variant: 'error' });
          },
        );

      // ApiClient
      // .then((res) => {
      //    showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
      //    setValues(initialValues);
      //    setOpen(false);
      // })
      // .catch(() => showMessage({ message: 'Neplatné uživatelské jméno', variant: 'error' }))
      // showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
    }
  }, [values, showMessage, apiClient]);

  const handleLogin = useLoginHandler(
    () => {
      setNotificationOpen(true);
      const afterLoginPath = location?.search
        ? (qs.parse(location?.search, { ignoreQueryPrefix: true })?.from as string)
        : '/';

      history.replace(afterLoginPath);
    },
    (err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Login Error ', err);
      }
      setError(true);
    },
  );
  const t = useTranslate();

  return (
    <Container component='main' className={classes.root} maxWidth='sm'>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleLogin(values.username, values.password);
        }}
      >
        <div className={classes.content}>
          <div className={classes.headline}>
            <LoginPageLogo />
          </div>
          <div className={classes.fields}>
            <div className={classes.selectLocale}>
              <LoginPageActionLocale />
            </div>
            {error && (
              <Alert severity='error'>
                <strong>{t('homePage/login/error1')}</strong> {t('homePage/login/error2')}
              </Alert>
            )}
            <TextField
              type='text'
              label={t('homePage/login/username')}
              onChange={handleChange('username')}
              value={values.username}
              fullWidth
              margin='normal'
            />
            <PasswordField
              label={t('homePage/login/password')}
              onChange={handleChange('password')}
              value={values.password}
              fullWidth
              margin='normal'
            />
          </div>
          <div className={classes.actions}>
            <Link component={'a'} onClick={() => setOpen((ps) => !ps)}>
              {t('homePage/login/forgottenPassword')}
            </Link>
            <Button type='submit' color='primary' variant='contained'>
              {t('homePage/login/label')}
            </Button>
          </div>
          <Collapse in={open}>
            <div className={classes.forgotFieldsContainer}>
              <TextField
                type='text'
                label={t('homePage/login/username')}
                onChange={handleChange('forgotUser')}
                value={values.forgotUser}
                fullWidth
                margin='normal'
              />
              <Button variant='contained' color='primary' size='small' onClick={handlePasswordReset}>
                {t('homePage/login/sendButton')}
              </Button>
            </div>
          </Collapse>
        </div>
      </form>
    </Container>
  );
};

LoginPage.displayName = 'LoginPage';

export default LoginPage;
